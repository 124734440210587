// import { personalUser } from './services/api/user'
import Vue from "vue";
import store from "../store/store.js";

export default (to, from, next) => {
  console.log(to.name, from.name, "ini");

  if (to.name == "forgot-password") {
    if (store.state.user.currentUser != null) {
      return next({ path: "/home" });
    }
    return next();
  }

  if (to.name == "reset-password") {
    if (store.state.user.currentUser != null) {
      return next({ path: "/home" });
    }
    return next();
  }
  if (
    to.name != "page-login" &&
    to.name != "auth-callback" &&
    to.name != "page-privacy-police"
  ) {
    const getInfoPersonal = store.dispatch("user/getInfoUserPersonal");
    getInfoPersonal
      .then(() => {
        Vue.prototype.$userLogin = store.state.user.currentUser;
        const hasMenu = store.dispatch("user/getMenuUser");
        hasMenu
          .then(async () => {
            console.log(to.name, store.state.user.hasMenu, "to name");
            if (to.name == "dashboard") {
              return next();
            }
            if (to.name == "home") {
              return next();
            }
            if (to.name == "change-password") {
              return next();
            }
            if (to.name == "main") {
              return next({ path: "/home" });
            }
            if (to.meta.parent != "" && Object.keys(to.meta).length > 0) {
              const menu = store.state.user.hasMenu.filter(function (menu) {
                return menu.name == to.meta.parent;
              });
              if (menu.length < 1) {
                return next({ name: "page-error-404" });
              }
              const checkMenu = menu[0].submenu.filter(function (menu) {
                return menu.url == to.meta.url;
              });
              if (checkMenu.length < 1) {
                return next({ name: "page-error-404" });
              }
            } else {
              // var checkMenuParent = store.state.user.hasMenu.filter(function(menu) {
              //     return menu.url == to.meta.url;
              // });
              // if (checkMenuParent.length < 1 && to.meta.url != "/") {
              //     return next({name :"page-error-404"});
              // }
            }
            let whiteList = store.state.operatingUnit.whiteListUrl;
            const hasNameFinance = to.matched.filter(
              (route) => route.name === "finance"
            );

            if (
              (whiteList.includes(to.name.toLowerCase()) ||
                hasNameFinance.length > 0) &&
              store.state.operatingUnit.filter.operatingUnit.length === 0
            ) {
              await store.dispatch(
                "operatingUnit/getOperatingUnitWithTerritory"
              );
              // return next();
            }

            whiteList = store.state.customerFilter.whiteListUrl;
            if (
              whiteList.includes(to.name.toLowerCase()) &&
              store.state.customerFilter.customers.length === 0
            ) {
              await store.dispatch("customerFilter/getAllCustomers");
            }
            return next();
          })
          .catch(() => {
            return next({ path: "/pages/login" });
          });
      })
      .catch(() => {
        return next({ path: "/pages/login" });
      });
  } else {
    return next();
  }
};
